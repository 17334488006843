import { fetchGet} from '../index';
//获取4956表格数据
export const getTableData = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/checking/Supporting/doc/page',{params})
}

//获取4956 Staus的下拉框内容
export const getSatusApi = (): Promise<any> => {
    return fetchGet('/claimapi/checking/Supporting/doc/getStatus')
}

//4956大文件下载
export const downloadDocumentsZip = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchGet('/claimapi/checking/Supporting/doc/download/schedule/zip' , {params})
}

